import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 15,
  },
  palette: {
    primary: { main: "#016D91" },
    // text: "#7A7A9B",
    text: {
      primary: "#7A7A9B",
      secondary: "#2D2C5F",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "12px",
        },
        outlined: {
          borderRadius: "10px",
          border: "1px solid rgba(255, 255, 255, 0.35)",
          color: {
            xs: "",
            md: "",
          },
          "@media(max-width:768px)": {
            border: "1px solid #016d91",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        },
        text: {
          color: "#fff",
          "@media(max-width:768px)": {
            color: "#016d91",
            padding: "8px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 4px 50px rgba(188, 188, 188, 0.25);",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontWeight: 500,
          fontSize: "15px",
          color: "#7A7A9B",
        },
      },
    },
  },
});

export default theme;
